import React from 'react';

import Layout from '@/components/Layout';
import { menuItems } from '@/config';
import content from '@/content/philosophy.json';
import SEO from '@/components/Seo';

export default function PhilosophyPage() {
  return (
    <Layout activeMenu={menuItems.philosophy}>
      <SEO description="" title="Philosophie" keywords={[`Philosophie`]} />
      <div className="content-padding">
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: content.text }} />
      </div>
    </Layout>
  );
}
